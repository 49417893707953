import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  constructor( private spinner : NgxSpinnerService) 
  {  }

  public spinnerShow(){
    this.spinner.show();
  }
  public spinnerHide(){
    this.spinner.hide();
  }
}

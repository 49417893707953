import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

// imports para poder darle formato al excel
import * as Excel from "exceljs/dist/exceljs.min.js";
// import * as ExcelProper from "exceljs";

import * as JSZip from 'jszip';
import { colors } from '@amcharts/amcharts4/core';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const EXCEL_EXTENSION = '.xlsx';


@Injectable()
export class ExcelService {

  doneExcel = false;

  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'], };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    this.saveAsExcelFile(excelBuffer, excelFileName);

  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);

  }

  // Los dos siguientes métodos fueron creados para los nuevos informes. Para no intervenir en la lógica de creación de los otros reportes, ya que se usa una nueva librería excel
  async crearExcelWithCustomHeader(json: any[], excelFileName: string, infoHeader, tipoInforme: string, tipo_dato: string = '', customHeaders: string[] = []) {

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("informe");

    // Información de archivo
    for (const h of infoHeader) {
      worksheet.addRow(h);
    }

    let colHeader = [];

    switch (tipoInforme) {

      case 'diario':
        colHeader = [
          ['Fecha - Hora', 'Kms Recorridos', 'KW Consumidos', 'SoC'],
        ];
        break;
      case 'mensual':
        colHeader = [
          ['Día', 'Kms Recorridos', 'KWh Cargados', 'Tiempo Carga Hrs', '% SoC Ini de la Carga', '% SoC Fin de la Carga'],
        ];
        break;
      case 'alarmas_estados':
        colHeader = [
          ['Fecha - Hora', 'Manguera', 'Estado', 'Descripción'],
        ];
        break;
      case 'alarmas_codigos':
        colHeader = [
          ['Nombre', 'Mensaje', 'Error Code', 'Fecha', 'Hora'],
        ];
        break;
      case 'alarmas_reinicios':
        colHeader = [
          ['Fecha', 'Vendor', 'Versión FW'],
        ];
        break;
      case 'cargas-bus':
        colHeader = [
          ['Bus', 'Manguera', 'Dia','Hora Inicio','Hora Fin', 'Minutos Carga' ,'% Inicio','% Fin', ' kWh', 'Cargador'],
        ];
        break;
      case 'cargas-usuario':
        colHeader = [
          ['Cargador', 'Manguera', 'Día', 'Hora Inicio', 'Hora Fin', ' Minutos Carga', '% Inicio', '% Fin', 'kWh'],
        ];
        break;
      case 'cargas-cargador':
        colHeader = [
          ['Cargador', 'Manguera', 'Día', 'Hora Inicio', 'Hora Fin', 'Minutos Cargas', ' % Inicio', '% Fin', 'kWh', 'Bus'],
        ];
        break;
      case 'cargas-cargador-vivipra':
        colHeader = [
          ['Fecha Fin', 'Equipo', 'Manguera', 'Potencia kWh', 'Cliente'],
        ];
        break;
      case 'codigos':
        colHeader = [
          ['Nombre', 'Mensaje', 'Error Code', 'Fecha', 'Hora'],
        ];
        break;
      case 'perfil-demanda':
        colHeader = [
          ['Fecha', 'kWh', 'Total Mangueras', 'Detalle'],
        ];
        break;
      case 'grafico-bus':
        let valorbus;
        if (tipo_dato != '') {
          valorbus = "Valor - " + tipo_dato;
        } else {
          valorbus = "Valor";
        }
        colHeader = [
          ['Fecha', 'Nombre Equipo', 'Tipo dato', valorbus],
        ];
        break;
      case 'grafico-manguera':
        let valormanguera;
        if (tipo_dato != '') {
          valormanguera = "Valor - " + tipo_dato;
        } else {
          valormanguera = "Valor";
        }
        colHeader = [
          ['Fecha', 'Nombre Equipo', 'Tipo dato', valormanguera],
        ];
        break;
      case 'grafico-medidores':
        let valormedidores;
        if (tipo_dato != '') {
          valormedidores = "Valor - " + tipo_dato.replace('[', '').replace(']', '');
        } else {
          valormedidores = "Valor";
        }
        colHeader = [
          ['Fecha', 'Nombre Equipo', 'Tipo dato', valormedidores],
        ];
        break;
      case 'reporte-equipos':
        colHeader = [
          ['Fecha', 'Nombre Equipo', 'Tipo dato', 'Valor'],
        ];
        break;
      case 'consumo-bus':
        let valorconsumo;
        if (tipo_dato == null || tipo_dato == '') {
          valorconsumo = "Valor";
        } else {
          valorconsumo = "Valor - " + tipo_dato;
        }
        colHeader = [
          ['Equipo', 'Fecha Inicio', 'Fecha Fin', 'Tipo Dato', valorconsumo],
        ];
        break;
      case 'consumo-mensual-terminal':
        colHeader = [
          ['Nombre Equipo', 'Manguera', 'Valor inicial', 'Valor final', 'Total manguera', 'Total cargador'],
        ];
        break;
      case 'grafico-bus-comparativo':
        colHeader = [
          [customHeaders[0], 'Hora', 'Valor', ' ', customHeaders[1], 'Hora', 'Valor'],
        ];
        break;

    }



    // Headers columnas
    for (const h of colHeader) {

      worksheet.addRow(h);
    }

    // Agrega Data desde json
    for (const row of json) {
      const keys = Object.keys(row);
      const temp = []
      for (const k of keys) {
        temp.push(row[k])
      }
      worksheet.addRow(temp)
    }

    if (tipoInforme == 'consumo-mensual-terminal') {

      worksheet['!merges'] = [{ s: { c: 1, r: 2 }, e: { c: 10, r: 30 } }]

      workbook['_worksheets'][1]['!merges'] = [{ s: { c: 1, r: 2 }, e: { c: 10, r: 30 } }]
    }


    // Por cada fila de la worksheet
    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {

      if (tipoInforme == 'perfil-demanda') {
        if (rowNumber === 1) {
          row.font = { bold: true };
        }
      }
      else if (tipoInforme == 'grafico-bus') {
        if (rowNumber === 1) {
          row.font = { bold: true };
        }
      }
      else if (tipoInforme == 'grafico-medidores') {
        if (rowNumber === 1) {
          row.font = { bold: true };
        }
      }
      else if (tipoInforme == 'grafico-manguera') {
        if (rowNumber === 1) {
          row.font = { bold: true };
        }
      }
      else if (tipoInforme == 'reporte-equipos') {
        if (rowNumber === 1) {
          row.font = { bold: true };
        }
      }
      else if (tipoInforme == 'consumo-bus') {
        if (rowNumber === 1) {
          row.font = { bold: true };
        }
      }
      else if (tipoInforme == 'consumo-mensual-terminal') {
        if (rowNumber === 1) {
          row.font = { bold: true };
        }
      }
      else if (tipoInforme == 'cargas-usuario') {
        if (rowNumber === 9) {
          row.font = { bold: true };
        }
      }
      else if (tipoInforme == 'grafico-bus-comparativo') {
        if (rowNumber === 1 ||
          rowNumber === 2 ||
          rowNumber == 3) {

          row.font = { bold: true };
        }

      } else {
        //  negrita al header y a fila TOTAL (informe diario)
        if (row.getCell(1).value === 'TOTAL' || rowNumber === 4) {
          row.font = { bold: true };
        }
      }

      // Alinear y asignar tipo de dato a cada celda
      row.eachCell((cell, colNumber) => {
        if (tipoInforme != 'perfil-demanda') {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        }
        if (tipoInforme == 'grafico-bus') {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        }
        if (tipoInforme == 'grafico-manguera') {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        }
        if (tipoInforme == 'grafico-medidores') {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        }
        if (tipoInforme == 'reporte-equipos') {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        }
        if (tipoInforme == 'cargas-usuario') {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        }
        else {
          // En Informe Perfil Demanda centra columnas menos el detalle
          if (colNumber != 4) {
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
          }
        }

        if (tipoInforme == 'consumo-bus') {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        }
        if (tipoInforme == 'consumo-mensual-terminal') {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        }
        if (tipoInforme == 'grafico-bus-comparativo') {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        }
        if (tipoInforme == 'cargas-cargador-vivipra') {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        }

        // Formato número a los valores para que no queden en 'General' en Informe Diario y Mensual
        if (tipoInforme != 'alarmas') {
          const valor = cell.value;
          if (!isNaN(valor.toString().replace(',', '.'))) {
            cell.numFmt = '#,##0';
          }
        }
      });

    });

    // Asigna un ancho
    for (let i = 1; i <= worksheet.actualColumnCount; i++) {
      worksheet.getColumn(i).width = 25;

      if (tipoInforme == 'alarmas' && i == 4) {
        worksheet.getColumn(i).width = 60;
      }
    }



    //#region Freeze
    if (tipoInforme == 'perfil-demanda') {
      worksheet.views = [
        { state: 'frozen', xSplit: 0, ySplit: 1 }
      ];
    }
    else if (tipoInforme == 'grafico-bus') {
      worksheet.views = [
        { state: 'frozen', xSplit: 0, ySplit: 1 }
      ];
    }
    else if (tipoInforme == 'grafico-manguera') {
      worksheet.views = [
        { state: 'frozen', xSplit: 0, ySplit: 1 }
      ];
    }
    else if (tipoInforme == 'grafico-medidores') {
      worksheet.views = [
        { state: 'frozen', xSplit: 0, ySplit: 1 }
      ];
    }
    else if (tipoInforme == 'reporte-equipos') {
      worksheet.views = [
        { state: 'frozen', xSplit: 0, ySplit: 1 }
      ];
    }
    else if (tipoInforme == 'consumo-bus') {
      worksheet.views = [
        { state: 'frozen', xSplit: 0, ySplit: 1 }
      ];
    }
    else if (tipoInforme == 'consumo-mensual-terminal') {
      worksheet.views = [
        { state: 'frozen', xSplit: 0, ySplit: 1 }
      ];
    }
    else if (tipoInforme == 'grafico-bus-comparativo') {
      worksheet.views = [
        { state: 'frozen', xSplit: 0, ySplit: 3 }
      ];
    }
    else if (tipoInforme == 'cargas-usuario') {
      worksheet.views = [
        { state: 'frozen', xSplit: 0, ySplit: 9 }
      ];
    }
    else {
      worksheet.views = [
        { state: 'frozen', xSplit: 0, ySplit: 4 }
      ];

    }
    //#endregion

    // Guardar
    const excel = await workbook.xlsx.writeBuffer()
      .then(data => {

        return { data, excelFileName };

      })
      .catch(e => {
        console.error(e);
        return {}
      });


    return excel;
  }

  saveAsExcelFileReport(buffer: any, fileName: string) {
    let done = false;

    try {
      const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
      done = true;

    } catch (error) {
      done = false;
    }

    return done;

  }

  async saveAsZipFile(arrayBuses, nombreCarpeta) {


    const zip = new JSZip();
    const folder = zip.folder(nombreCarpeta);

    for (const file of arrayBuses) {

      const data: Blob = new Blob([file.data], { type: EXCEL_TYPE });
      folder.file(`${file.excelFileName}.xlsx`, data);
    }

    await zip.generateAsync({ type: "blob" }).then((content) => {
      // see FileSaver.js
      FileSaver.saveAs(content, nombreCarpeta);
      // saveAs(content, "example.zip");
    });

  }
  // Método custom creado para generar Excel de informe de Alarmas en un solo archivo
  async crearExcelAlarmasUnicoArchivo(infoTotal, excelFileName: string, tipoInforme: string) {

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("informe");

    let colHeader = [];
    switch (tipoInforme) {

      case 'alarmas_estados':
        colHeader = [
          ['Fecha - Hora', 'Manguera', 'Estado', 'Descripción'],
        ];
        break;
      case 'alarmas_codigos':
        colHeader = [
          ['Nombre', 'Mensaje', 'Error Code', 'Fecha', 'Hora'],
        ];
        break;
      case 'alarmas_reinicios':
        colHeader = [
          ['Fecha', 'Vendor', 'Versión FW'],
        ];
        break;

    }

    for (const info of infoTotal) {

      worksheet.addRow([info.cargador]);
      // Headers columnas
      for (const h of colHeader) {

        worksheet.addRow(h);
      }

      // Agrega Data desde json
      for (const row of info.datos) {
        const keys = Object.keys(row);
        const temp = []
        for (const k of keys) {
          temp.push(row[k])
        }
        worksheet.addRow(temp)
      }

      // Espacios en blanco
      worksheet.addRow();
      worksheet.addRow();

    }

    // Por cada fila de la worksheet
    worksheet.eachRow({ includeEmpty: true }, (row) => {

      //  Negrita al header de cada segmento
      if (row.getCell(1).value === 'Fecha - Hora' || row.getCell(1).value === 'Nombre' || row.getCell(1).value === 'Fecha') {
        row.font = { bold: true };
      }

      // Alinear
      row.eachCell((cell, colNumber) => {
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
      });

    });

    // Asigna un ancho
    for (let i = 1; i <= worksheet.actualColumnCount; i++) {
      worksheet.getColumn(i).width = 25;
    }

    // Guardar
    const excel = await workbook.xlsx.writeBuffer()
      .then(data => {

        return { data, excelFileName };
      })
      .catch(e => {
        console.error(e);
        return {}
      });

    return excel;
  }

  /// REQUIERE UN OBJETO {infoHeader,data,sheetName}
  async ExcelMultiPaginaCargasUsuario(json: any[], fileName:string, cols) {

    const workbook = new Excel.Workbook();
    json.forEach(x => {

      const worksheet = workbook.addWorksheet(x.sheetName);
      // Información de archivo
      for (const h of x.infoHeader) {
        worksheet.addRow(h);
      }
      //columnas
      for (const h of cols) {
        worksheet.addRow(h);
      }

      // Agrega Data desde json
      for (const row of x.data) {
        const keys = Object.keys(row);
        const temp = []
        for (const k of keys) {
          temp.push(row[k])
        }
        worksheet.addRow(temp)
      }
      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        if (rowNumber === 9) {
          row.font = { bold: true };
        }

        row.eachCell((cell, colNumber) => {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        })
      })

      // Asigna un ancho
      for (let i = 1; i <= worksheet.actualColumnCount; i++) {
        worksheet.getColumn(i).width = 25;

      }
    })
    // Guardar
    const excel = await workbook.xlsx.writeBuffer()
      .then(data => {

        return { data, fileName };

      })
      .catch(e => {
        console.error(e);
        return {}
      });


    return excel;
  }

}

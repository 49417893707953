import { Component, OnInit, Inject, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Ubicacion } from 'src/app/models/ubicacion.interface';
import { waypoints } from 'src/app/models/waypoints.interface';
import { GeocodeService } from 'src/app/sharedServices/geocode.service';
import { Observable, from } from 'rxjs';
// import { fromPromise } from 'rxjs/add/observable/fromPromise';
declare const google: any;

@Component({
  selector: 'app-map-actual',
  templateUrl: './map-actual.component.html',
  styleUrls: ['./map-actual.component.scss']
})
export class MapActualComponent implements OnInit {

   geoCoder;
   waypoint: any;
   lat;
   lng;
   rad;
   zoom: number = 16;
   direccion: any = "Cargando direccion...";
   nombre: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private gCService: GeocodeService,
    private ref: ChangeDetectorRef) { }


  ngOnInit() {

    // this.geoCoder = new google.maps.Geocoder();
    this.waypoint = this.data.waypoint;
    this.lat = this.waypoint.latitud;
    this.lng = this.waypoint.longitud;
    this.rad = this.waypoint.radio;
    this.nombre = this.waypoint.nombre;


    this.gCService.geocodeAddress(this.waypoint.direcciones.descripcion).subscribe(val => {



    })
    this.gCService.reverseGeocodeAddress(this.lat, this.lng).subscribe(val => {

      this.direccion = val.address;
      this.ref.detectChanges();
    });
  }



  getAddress(latitude, longitude) {

    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {

      if (status == google.maps.GeocoderStatus.OK) {
        if (results[0] != null) {
          this.direccion = results[0].formatted_address;

        } else {
          this.direccion = "Direccion no encontrada"
        }
      }
    }
    );
  }

  getCoord(query: string) {
    var lt, lg;
    return new Promise((resolve, reject) => {
      this.geoCoder.geocode({ 'address': query }, (result, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          lt = result[0].geometry.location.lat()
          lg = result[0].geometry.location.lng()
     


        }
      }
      );
    });
  }

}

<!-- TOP NAV -->
<mat-toolbar>


    <button fxHide fxShow.lt-lg="true" *ngIf="isAuthenticated()" type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
        <mat-icon aria-label="Sidenav toggle icon"><label color="dark">menu</label></mat-icon>
    </button>

    <img src="{{fullUri}}" class="dhemax-logo" alt="">

    <span *ngIf="isAuthenticated() && checkPermisoUsuario() === 'CUTCSA'">
         Monitor de recorrido: desde 5AM hasta ahora
    </span>
    <span _ngcontent-c1="" class="spacer"></span>

    <!-- MENU -->
    <section fxHide.lt-lg fxFlex fxLayout fxLayoutAlign="end"*ngIf="isAuthenticated()" [style.z-index]="zIndexValue" >

        <!-- INFORMES -->

        <button disableRipple #button #levelOneTrigger="matMenuTrigger"  (mouseenter)="buttonEnter(levelOneTrigger)" (mouseleave)="buttonLeave(levelOneTrigger, button)"  [ngClass]="{'boton-activo hover-activo': botonActivo === 1}"  class="nav-button informes-hov" style="width: 128px;" [matMenuTriggerFor]="inf" *ngIf="obtenerParent(parents.INFORMES)">
            <img style="padding-left: 0px !important" *ngIf="botonActivo=== 1" src="/assets/img/menu-icons/informesAct.svg">
            <img style="padding-left: 0px !important" *ngIf="botonActivo != 1" src="/assets/img/menu-icons/informes.svg">
            <span [ngClass]="{'texto-activo': botonActivo === 1}" style=" font-size:20px;"> Informes </span>
        </button>
        <mat-menu #inf="matMenu" [overlapTrigger]="false" >
            <span (mouseenter)="menuenter()" (mouseleave)="menuLeave(inf, button)">

                <div  *ngFor="let item of informes" >
                    <a  style="background-color:white" class="c-button" disableRipple [ngClass]="{'sub-activo': botonActivoMain === item}" (click)="seleccionarBotonMain2(2);seleccionarBotonMain(item); toggleA() ; seleccionarBoton(1) ;seleccionarBotonSubs(2)" class="c-button" mat-menu-item [routerLink]="[item.ruta]" *ngIf="item.status">
                        <span   [ngClass]="{'sub-texto-activo': botonActivoMain === item}"  style="font-size: 19px;">{{item.texto}}</span>
                    </a>
                </div>
            </span>
        </mat-menu >

        <!-- EQUIPOS -->
        <button disableRipple #button #levelOneTrigger="matMenuTrigger" (mouseenter)="buttonEnter(levelOneTrigger)" (mouseleave)="buttonLeave(levelOneTrigger, button)"  [ngClass]="{'boton-activo hover-activo': botonActivo === 2}" class="nav-button equipos-hov" style="width: 122px;" [matMenuTriggerFor]="ed" *ngIf="obtenerParent(parents.EQUIPOS)">
          <img  *ngIf="botonActivo === 2" src="/assets/img/menu-icons/equiposAct.svg">
          <img  style="padding-left:10px !important" *ngIf="botonActivo != 2" src="/assets/img/menu-icons/equipos.svg">
            <span style="font-size:20px;" [ngClass]="{'texto-activo': botonActivo === 2}"> Equipos</span>
        </button>

        <mat-menu #ed="matMenu" [overlapTrigger]="false">
            <span (mouseenter)="menuenter()" (mouseleave)="menuLeave(ed, button)">

                <div *ngFor="let item of rutasEquipos">
                    <a  style="background-color:white" class="c-button" disableRipple [ngClass]="{'sub-activo': botonActivoMain === item}" (click)="seleccionarBotonMain2(2);seleccionarBotonMain(item); toggleA() ;seleccionarBoton(2); seleccionarBotonSubs(2)" mat-menu-item [routerLink]="[item.ruta]" *ngIf="item.status">
                        <span style="font-size: 19px;"  [ngClass]="{'sub-texto-activo': botonActivoMain === item}" > {{item.texto}}</span>
                    </a>
                </div>
            </span>
        </mat-menu>

        <!-- GRAFICOS -->
        <button  #button #levelOneTrigger="matMenuTrigger" (mouseenter)="buttonEnter(levelOneTrigger)" (mouseleave)="buttonLeave(levelOneTrigger, button)" class="nav-button graficos-hov" style="width: 125px;" [ngClass]="{'boton-activo hover-activo': botonActivo === 3}"  disableRipple [matMenuTriggerFor]="grafico" *ngIf="obtenerParent(parents.GRAFICOS)">
          <img  *ngIf="botonActivo === 3" src="/assets/img/menu-icons/gráficosAct.svg">
          <img  *ngIf="botonActivo != 3" src="/assets/img/menu-icons/gráficos.svg">
             <span style="font-size:20px;" [ngClass]="{'texto-activo': botonActivo === 3}" >Gráficos</span>
        </button>

        <mat-menu #grafico="matMenu" [overlapTrigger]="false">
            <span (mouseenter)="menuenter()" (mouseleave)="menuLeave(grafico, button)">
                <div *ngFor="let item of rutasGraficos">
                    <!-- <div *ngIf="botonActivo==3"> -->

                            <a style="background-color:white" disableRipple [ngClass]="{'sub-activo': botonActivoMain === item}" class="c-button" (click)="seleccionarBotonMain2(2); seleccionarBoton(3);seleccionarBotonMain(item); toggleA() ; seleccionarBotonSubs(2)" mat-menu-item [routerLink]="[item.ruta]" *ngIf="item.status">
                                <span style="font-size:19px" [ngClass]="{'sub-texto-activo': botonActivoMain === item}" >{{item.texto}}</span>
                            </a>
                            <!-- </div> -->
                    </div>
                </span>
        </mat-menu>

        <!-- BUSES -->
        <button #button #levelOneTrigger="matMenuTrigger"  (mouseenter)="buttonEnter(levelOneTrigger)" (mouseleave)="buttonLeave(levelOneTrigger, button)" class="nav-button buses-hov"  style="width: 104px;" disableRipple [matMenuTriggerFor]="bus" [ngClass]="{'boton-activo hover-activo': botonActivo === 4}" *ngIf="obtenerParent(parents.BUSES)">
          <img  style="padding-left:0px !important" *ngIf="botonActivo == 4" src="/assets/img/menu-icons/busesAct.svg">
          <img  style="padding-left:0px !important" *ngIf="botonActivo != 4" src="/assets/img/menu-icons/buses.svg">
            <span style=" font-size:20px;" [ngClass]="{'texto-activo': botonActivo === 4}">Buses</span>
        </button>

        <mat-menu #bus="matMenu" [overlapTrigger]="false">
            <span (mouseenter)="menuenter()" (mouseleave)="menuLeave(bus, button)">

                <div *ngFor="let item of rutasBus">

                        <a style="background-color:white" disableRipple [ngClass]="{'sub-activo': botonActivoMain === item}" class="c-button" (click)="seleccionarBotonMain2(2);seleccionarBotonMain(item); toggleA() ; seleccionarBoton(4); seleccionarBotonSubs(2)" mat-menu-item [routerLink]="[item.ruta]" *ngIf="item.status">
                            <span style="font-size: 19px;" [ngClass]="{'sub-texto-activo': botonActivoMain === item}" >{{item.texto}}</span>
                        </a>
                </div>
            </span>

        </mat-menu>

        <!-- ADMINISTRACION -->
        <button #button #levelOneTrigger="matMenuTrigger"  (mouseenter)="buttonEnter(levelOneTrigger)" (mouseleave)="buttonLeave(levelOneTrigger, button)" class="nav-button administracion-hov"  style="width: 189px;" disableRipple [ngClass]="{'boton-activo hover-activo': botonActivo === 7}"  [matMenuTriggerFor]="administracion" *ngIf="obtenerParent(parents.ADMINISTRACION) || obtenerParent(parents.CONFIGURACION)">
          <img  *ngIf="botonActivo === 7" src="/assets/img/menu-icons/adminAct.svg">
          <img  style="padding-left:9px !important" *ngIf="botonActivo != 7" src="/assets/img/menu-icons/admin.svg">
            <span style=" font-size:20px;" [ngClass]="{'texto-activo': botonActivo === 7}"> Administración</span>
        </button>
        <mat-menu #administracion="matMenu" [overlapTrigger]="false" >
            <span (mouseenter)="menuenter()" (mouseleave)="menuLeave(administracion, button)">

                <div *ngFor="let item of rutasAdministracion" >
                    <a style="background-color:white;" disableRipple [ngClass]="{'sub-activo': botonActivoMain === item}" class="c-button" (click)="seleccionarBotonMain(item); toggleA(); seleccionarBoton(7); seleccionarBotonSubs(2);seleccionarBotonMain2(2)" mat-menu-item [routerLink]="[item.ruta]" *ngIf="item.status">
                        <span style= "font-size: 19px;" [ngClass]="{'sub-texto-activo': botonActivoMain === item}"  >{{item.texto}}</span>
                    </a>
                </div>
              <mat-accordion #configuracion="matAccordion" hideToggle="true">
                <mat-panel-title >
                </mat-panel-title>
                <!-- SUB MENU -->
                <mat-expansion-panel [expanded]="showSubsubmenu" hideToggle style="padding: 0;border-radius:0px;" >
                    <mat-expansion-panel-header style="width: 280px !important; padding: 0px 0px !important; background-color:white ">
                        <a style="background-color:white" disableRipple class="c-button menu-item" [ngClass]="{'sub-activo': botonActivoMain2 == true}" (mouseenter)="toggleSubsubmenu($event)" (mouseleave)="toggleSubsubmenu($event)" (click)="seleccionarBotonMain2(1);seleccionarBotonMain(item) ;seleccionarBoton(7) ;seleccionarBotonSubs(1); toggleB()" mat-menu-item  *ngIf="obtenerParent(parents.CONFIGURACION)">
                                <span style="font-size: 19px" [ngClass]="{'sub-texto-activo': botonActivoMain2 == true }">Configuración
                                    <img style="position: relative; left: 85px;" *ngIf="botonActivoMain === item  " src="/assets/img/menu-icons/flechaAct.svg">&nbsp;
                                    <img style="position: relative; left: 80px"  *ngIf="showSubsubmenu==false" src="/assets/img/menu-icons/flecha.svg"> &nbsp;
                                    <img style="position: relative; left: 63px"  *ngIf="showSubsubmenu ==true" src="/assets/img/menu-icons/flechaHov.svg"> &nbsp; </span>
                                </a>
                                </mat-expansion-panel-header>
                                <div [hidden]="false" class="custom-expansion-panel" >
                                    <div *ngFor="let item of rutasConfiguracion" style=" padding: 0px; width: 280px;"  >
                                        <a disableRipple [ngClass]="{'sub-activo': botonActivoSub === item}" class="c-button menu-item2" mat-menu-item (mouseenter)="toggleSubsubmenu($event)" (mouseleave)="toggleSubsubmenu($event)" [routerLink]="[item.ruta]" *ngIf="item.status"  (click)="seleccionarBotonMain2(1);seleccionarBotonSub(item); ; seleccionarBoton(7);seleccionarBotonMain(item.rutasAdministracion)">
                                            <span  [ngClass]="{'sub-texto-activo-submenu1': botonActivoSub === item }"  style="width: 280px;  font-size: 19px ;padding-left: 50px;">{{item.texto}}</span>
                                        </a>
                                    </div>
            </div>
        </mat-expansion-panel>
            </mat-accordion>
        </span>
        </mat-menu>
        <!-- MANTENEDORES -->
        <button #button #levelOneTrigger="matMenuTrigger" (mouseenter)="buttonEnter(levelOneTrigger)" (mouseleave)="buttonLeave(levelOneTrigger, button)" class="nav-button mantenedores-hov"  style="width: 179px;" disableRipple [ngClass]="{'boton-activo hover-activo': botonActivo === 5}" [matMenuTriggerFor]="mantenedores" *ngIf="obtenerParent(parents.MANTENEDORES)">
          <img  style="padding-left: 0px !important" *ngIf="botonActivo === 5" src="/assets/img/menu-icons/mantenedoresAct.svg">
          <img  style="padding-left: 0px !important" *ngIf="botonActivo != 5" src="/assets/img/menu-icons/mantenedores.svg">
            <span style=" font-size:20px;" [ngClass]="{'texto-activo': botonActivo === 5}">Mantenedores</span>
        </button>

        <mat-menu #mantenedores="matMenu" style="padding-left:10px;" [overlapTrigger]="false" >
            <mat-nav-list style='width: 280px;'>
                <span (mouseenter)="menuenter()" (mouseleave)="menuLeave(grafico, button)">

                    <div *ngFor="let item of rutasMantenedores">

                        <a style="background-color: white;" disableRipple [ngClass]="{'sub-activo': botonActivoMain === item}" (click)="seleccionarBotonMain2(2);seleccionarBotonMain(item); seleccionarBoton(5) ; toggleA() ; seleccionarBotonSubs(2)"class="c-button" mat-menu-item [routerLink]="[item.ruta]" *ngIf="item.status" (click)="showRute(item.ruta)">
                            <span style="font-size: 19px;" [ngClass]="{'sub-texto-activo': botonActivoMain === item}">{{item.texto}}</span>
                        </a>
                    </div>
                </span>
            </mat-nav-list>
        </mat-menu>

        <!-- SOPORTE-->
        <button #button #levelOneTrigger="matMenuTrigger" (mouseenter)="buttonEnter(levelOneTrigger)" (mouseleave)="buttonLeave(levelOneTrigger, button)" class="nav-button soporte-hov" style="width: 122px;" disableRipple [ngClass]="{'boton-activo soporte-hov-activo': botonActivo === 6}"  disableRipple [matMenuTriggerFor]="soporte" *ngIf="obtenerParent(parents.SOPORTE) || obtenerParent(parents.PROGRAMA_HORARIO)">
          <img style="padding-left: 3px !important" *ngIf="botonActivo === 6" src="/assets/img/menu-icons/soporteAct.svg">
          <img style="padding-left: 0px !important" *ngIf="botonActivo != 6" src="/assets/img/menu-icons/soporte.svg">
            <span style=" font-size:20px;" [ngClass]="{'texto-activo': botonActivo === 6}" >Soporte</span>
        </button>
        <mat-menu #soporte="matMenu" [overlapTrigger]="false" >
            <span (mouseenter)="menuenter()" (mouseleave)="menuLeave(soporte, button)">
                <div *ngFor="let item of rutasSoporte" >
                    <a style="background-color: white;" [ngClass]="{'sub-activo': botonActivoMain === item}" class= "c-button "  (click)="seleccionarBotonMain2(2);seleccionarBotonMain(item);seleccionarBoton(6) ;toggleA() ; seleccionarBotonSubs(2)" mat-menu-item disableRipple [routerLink]="[item.ruta]" *ngIf="item.status">
                        <span  style="font-size: 19px;"  [ngClass]="{'sub-texto-activo': botonActivoMain === item}"> {{item.texto}}</span>
                    </a>
                </div>
                <mat-accordion #SOPORTE="matAccordion" hideToggle="true" style=" padding: 0px;">
                    <mat-panel-title>
                    </mat-panel-title>
              <!-- SUB MENU -->
              <mat-expansion-panel [expanded]="showSubsubmenu" hideToggle style="padding: 0; width: 280px;border-radius:0px">
                <mat-expansion-panel-header #subPanel class="expansion-panel-class" #panel style="width: 290px !important;padding: 0px 0px !important;  " (mouseenter)="toggleSubsubmenu($event)" (mouseleave)="toggleSubsubmenu($event)">
                    <a  style="background-color: white" disableRipple class="c-button menu-item" [ngClass]="{'sub-activo': botonActivoMain3 == true}" (click)="seleccionarBotonMain2(3);seleccionarBotonMain(item);seleccionarBoton(6);  toggleB()" mat-menu-item  *ngIf="obtenerParent(parents.PROGRAMA_HORARIO)">
                    <span style="font-size: 19px;"[ngClass]="{'sub-texto-activo': botonActivoMain3 == true}">Programa Horario
                    <img style="position: relative; left: 53px;" *ngIf="botonActivoMain === item " src="/assets/img/menu-icons/flechaAct.svg">&nbsp;
                    <img style="position: relative; left: 60px"  *ngIf="showSubsubmenu==false" src="/assets/img/menu-icons/flecha.svg"> &nbsp;
                    <img style="position: relative; left: 33px"  *ngIf="showSubsubmenu ==true" src="/assets/img/menu-icons/flechaHov.svg"> &nbsp; </span></a>
              </mat-expansion-panel-header>
              <div [hidden]="false" class="custom-expansion-panel">
                <div *ngFor="let item of rutasProgramaHorario">
                    <a disableRipple mat-menu-item (mouseenter)="toggleSubsubmenu($event)" class="c-button menu-item3" (mouseleave)="toggleSubsubmenu($event)" [routerLink]="[item.ruta]" *ngIf="item.status" [ngClass]="{'sub-activo': botonActivoSub === item}" (click)="seleccionarBotonMain2(3);seleccionarBotonSub(item);seleccionarBoton(6); seleccionarBotonMain(item.rutasSoporte)">
                    <span  [ngClass]="{'sub-texto-activo-submenu': botonActivoSub === item}"  style="font-size: 19px;">{{item.texto}}</span>
                </a>

            </div>
              </div>

            </mat-expansion-panel>
          </mat-accordion>
        </span>

      </mat-menu>


    </section>

    <!-- DESCONECTARSE -->
    <div *ngIf="!disableSalir">

        <button disableRipple (click)="logOut()" *ngIf="isAuthenticated()" class="align-end btnSalir">

            <span style=" font-size:20px;">Salir</span>
        </button>
    </div>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container">

    <!-- SIDE NAV -->
    <mat-sidenav #drawer class="sidenav" fixedInViewport="false" role="navigation" mode="over">

        <mat-toolbar style="border-bottom: 2px solid #e0e0e0;">
            <span class="titulo-plataforma">MENU</span>
            <span class="spacer"></span>

            <!-- SALIR -->
            <button mat-flat-button (click)="logOut(); drawer.toggle()" *ngIf="isAuthenticated()" style="height: 100%;">
              <mat-icon>exit_to_app</mat-icon> SALIR
            </button>
        </mat-toolbar>

        <mat-accordion *ngIf="isAuthenticated()">

            <!-- INFORMES -->
            <mat-expansion-panel *ngIf="obtenerParent(parents.INFORMES)">
                <mat-expansion-panel-header>
                    INFORMES
                </mat-expansion-panel-header>
                <mat-nav-list>
                    <div *ngFor="let item of informes">
                        <a mat-menu-item *ngIf="item.status" [routerLink]="[item.ruta]" (click)="drawer.toggle()">
                            <mat-icon matListIcon class="app-nav-list-icon">{{item.icon}}</mat-icon>&nbsp; {{ item.texto }}
                        </a>
                    </div>
                </mat-nav-list>
            </mat-expansion-panel>

            <!--EQUIPOS -->
            <mat-expansion-panel *ngIf="obtenerParent(parents.EQUIPOS)">
                <mat-expansion-panel-header>
                    EQUIPOS
                </mat-expansion-panel-header>
                <mat-nav-list>
                    <div *ngFor="let item of rutasEquipos">
                        <a mat-list-item *ngIf="item.status" routerLinkActive routerLinkActive="list-item-active" [routerLink]="[item.ruta]" (click)="drawer.toggle()">
                            <mat-icon matListIcon class="app-nav-list-icon">{{item.icon}}</mat-icon>
                            {{item.texto}}
                        </a>
                    </div>
                </mat-nav-list>
            </mat-expansion-panel>

            <!-- GRÁFICOS -->
            <mat-expansion-panel *ngIf="obtenerParent(parents.GRAFICOS)">
                <mat-expansion-panel-header>
                    GRAFICOS
                </mat-expansion-panel-header>
                <mat-nav-list>
                    <div *ngFor="let item of rutasGraficos">
                        <a mat-list-item *ngIf="item.status" routerLinkActive routerLinkActive="list-item-active" [routerLink]="[item.ruta]" (click)="drawer.toggle()">
                            <mat-icon matListIcon class="app-nav-list-icon">{{item.icon}}</mat-icon>
                            {{item.texto}}
                        </a>
                    </div>

                </mat-nav-list>
            </mat-expansion-panel>

            <!-- BUSES -->
            <mat-expansion-panel *ngIf="obtenerParent(parents.BUSES)">
                <mat-expansion-panel-header>
                    BUSES
                </mat-expansion-panel-header>
                <mat-nav-list>
                    <div *ngFor="let item of rutasBus">
                        <a mat-list-item *ngIf="item.status" routerLinkActive routerLinkActive="list-item-active" [routerLink]="[item.ruta]" (click)="drawer.toggle()">
                            <mat-icon matListIcon class="app-nav-list-icon">{{item.icon}}</mat-icon>
                            {{item.texto}}
                        </a>
                    </div>
                </mat-nav-list>
            </mat-expansion-panel>

            <!-- ADMINISTRACION -->
            <mat-expansion-panel *ngIf="obtenerParent(parents.ADMINISTRACION) || obtenerParent(parents.CONFIGURACION)">
                <mat-expansion-panel-header>
                    ADMINISTRACIÓN
                </mat-expansion-panel-header>
                <mat-nav-list>
                    <div *ngFor="let item of rutasAdministracion">
                        <a mat-list-item *ngIf="item.status" routerLinkActive routerLinkActive="list-item-active" [routerLink]="[item.ruta]" (click)="drawer.toggle()">
                            <mat-icon matListIcon class="app-nav-list-icon">{{item.icon}}</mat-icon>
                            {{item.texto}}
                        </a>
                    </div>
                </mat-nav-list>

                <!-- CONFIGURACION -->
                <div *ngIf="obtenerParent(parents.CONFIGURACION)">
                    <span class="span-disabled">CONFIGURACIÓN</span>
                    <mat-nav-list>
                        <div *ngFor="let item of rutasConfiguracion">
                            <a mat-list-item *ngIf="item.status" routerLinkActive routerLinkActive="list-item-active" [routerLink]="[item.ruta]" (click)="drawer.toggle()">
                                <mat-icon matListIcon class="app-nav-list-icon">{{item.icon}}</mat-icon>
                                {{item.texto}}
                            </a>
                        </div>
                    </mat-nav-list>
                </div>
            </mat-expansion-panel>

            <!--MANTENEDORES-->
            <mat-expansion-panel *ngIf="obtenerParent(parents.MANTENEDORES)">
                <mat-expansion-panel-header>
                    MANTENEDORES
                </mat-expansion-panel-header>
                <mat-nav-list>
                    <!-- TIPOS DE DATOS, FUNCIONALIDADES   -->
                    <mat-nav-list>
                        <div *ngFor="let item of rutasMantenedores">
                            <a mat-list-item *ngIf="item.status" routerLinkActive [routerLink]="[item.ruta]" (click)="drawer.toggle()">
                                <mat-icon matListIcon class="app-nav-list-icon">{{item.icon}}</mat-icon>
                                {{item.texto}}
                            </a>
                        </div>
                    </mat-nav-list>
                </mat-nav-list>
            </mat-expansion-panel>



            <!-- SOPORTE -->
            <mat-expansion-panel *ngIf="obtenerParent(parents.SOPORTE) || obtenerParent(parents.PROGRAMA_HORARIO)">
                <mat-expansion-panel-header>
                    SOPORTE
                </mat-expansion-panel-header>
                <mat-nav-list>
                    <div *ngFor="let item of rutasSoporte">
                        <a mat-list-item *ngIf="item.status" [routerLink]="[item.ruta]" (click)="drawer.toggle()">
                            <mat-icon matListIcon class="app-nav-list-icon">{{item.icon}}</mat-icon>
                            {{item.texto}}
                        </a>
                    </div>
                </mat-nav-list>

                <!-- PROGRAMA HORARIO -->
                <div *ngIf="obtenerParent(parents.PROGRAMA_HORARIO) && empresaID != 33">
                    <span style="border-radius: 0px" class="span">PROGRAMA HORARIO</span>
                    <mat-nav-list >
                        <div *ngFor="let item of rutasProgramaHorario">
                            <a mat-list-item *ngIf="item.status" routerLinkActive [routerLink]="[item.ruta]" (click)="drawer.toggle()">
                                <mat-icon matListIcon class="app-nav-list-icon">{{item.icon}}</mat-icon>
                                {{item.texto}}
                            </a>
                        </div>
                    </mat-nav-list>
                </div>
            </mat-expansion-panel>

        </mat-accordion>

    </mat-sidenav>

    <!-- ACÁ SE ENCUENTRA EL CONTENIDO DE LA PÁGINA -->
    <mat-sidenav-content>
        <ngx-spinner
        bdColor="rgba(51,51,51,1)"
        size="medium"
        color="#fff"
        >
        <p style="font-size: 20px; color: white"> Cargando </p>
      </ngx-spinner>
        <router-outlet></router-outlet>
    </mat-sidenav-content>

</mat-sidenav-container>


<div class="confirm-dialog" fxLayout="column">
  <section fxFlex=80 class="mat-body" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="center center">
    <span>
      {{message.msg1}}
    </span>
    <span>
      {{message.msg2}}
    </span>
  </section>
  <section fxFlex=20 fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
    <button mat-raised-button [mat-dialog-close]="true" color="primary" cdkFocusInitial>Ok</button>
    <button mat-raised-button (click)="onNoClick()" color="warn">Cancelar</button>
  </section>
</div>
import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  title = 'Plataforma de Monitoreo';

  constructor(
    private auth : AuthService,
    private router: Router
  ){}

  ngOnInit(): void {
    if(!this.auth.loggedIn()){
      this.router.navigate(['Home']);
    }
  }
}



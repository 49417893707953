import { MatPaginatorIntl } from '@angular/material';

const espRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) { return `0 de ${length}`; }
  length = Math.max(length, 0);
  const startIndex = page * pageSize;
  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ?
    Math.min(startIndex + pageSize, length) :
    startIndex + pageSize;
  return `${startIndex + 1} - ${endIndex} de ${length}`;
}

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.getRangeLabel = espRangeLabel;
  customPaginatorIntl.itemsPerPageLabel = 'Cantidad por pagina :';
  customPaginatorIntl.firstPageLabel = 'Primera Pagina';
  customPaginatorIntl.lastPageLabel = 'Ultima Pagina';
  customPaginatorIntl.lastPageLabel = 'Ultima Pagina';
  customPaginatorIntl.nextPageLabel = 'Siguiente';
  customPaginatorIntl.previousPageLabel = 'Anterior';

  return customPaginatorIntl;
}
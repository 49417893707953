<mat-toolbar  class="main-nav-toolbar" color="primary">
  <span>{{direccion}}</span>
  <span class="spacer"></span>
  <button class="pulse-button" mat-raised-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<!-- Mapa -->
<agm-map class="actual-agm-map" [zoomControl]="false" [streetViewControl]="false" [latitude]="lat" [zoom]="zoom"
  [longitude]="lng">
  <agm-marker [latitude]="lat" [longitude]="lng">
    <agm-info-window [disableAutoPan]="false" [isOpen]="true">
      <strong>{{nombre}}</strong>
    </agm-info-window>
  </agm-marker>
  <agm-circle [latitude]="lat" [longitude]="lng" [radius]="rad" [fillColor]="'red'">
  </agm-circle>
</agm-map>
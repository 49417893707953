import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatInputModule, MatButtonModule, MatSelectModule, MatIconModule, MatCardModule, MatProgressBarModule,
  MatGridListModule, MatSidenavModule, MatToolbarModule, MatListModule,
  MatTooltipModule, MatDividerModule, MatSliderModule, MatProgressSpinnerModule, MatSnackBarModule,
  MatBottomSheetModule, MatFormFieldModule, MatDialogModule, MatTabsModule, MatBadgeModule,
  MatAutocompleteModule,
  MatButtonToggleModule,
  MatChipsModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatRadioModule,
  MatRippleModule,
  MatSlideToggleModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTreeModule,
  MatOptionModule,
  MatMenuModule,
  MatCheckboxModule,
  MatPaginatorIntl
} from '@angular/material';
import { CdkTableModule } from '@angular/cdk/table';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
//LOCALE
import { LOCALE_ID } from '@angular/core';
import localeEsCL from '@angular/common/locales/es-CL';
import { registerLocaleData } from '@angular/common';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { CustomPaginator } from './sharedComponents/CustomPaginator';
import { DragDropModule } from '@angular/cdk/drag-drop';

registerLocaleData(localeEsCL, 'es-CL');

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    MatGoogleMapsAutocompleteModule,
    MatSidenavModule,
    MatInputModule,
    MatToolbarModule,
    MatTooltipModule,
    MatDividerModule,
    MatFormFieldModule,
    MatOptionModule,
    MatButtonModule,
    MatSelectModule,
    MatBadgeModule,
    MatListModule,
    MatIconModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatSnackBarModule,
    MatSliderModule,
    MatProgressBarModule,
    MatGridListModule,
    MatTabsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatBottomSheetModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatRadioModule,
    MatRippleModule,
    MatSlideToggleModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTreeModule,
    MatMenuModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    LayoutModule,
    MatCheckboxModule,
    FlexLayoutModule,
    FormsModule,
    CdkTableModule,
    DragDropModule
  ],
  exports: [
    CommonModule,
    MatSidenavModule,
    MatInputModule,
    MatToolbarModule,
    MatTooltipModule,
    MatDividerModule,
    MatFormFieldModule,
    MatOptionModule,
    MatButtonModule,
    MatSelectModule,
    MatBadgeModule,
    MatListModule,
    MatIconModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatSnackBarModule,
    MatSliderModule,
    MatProgressBarModule,
    MatGridListModule,
    MatTabsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatBottomSheetModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatRadioModule,
    MatRippleModule,
    MatSlideToggleModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTreeModule,
    MatMenuModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    LayoutModule,
    FlexLayoutModule,
    FormsModule,
    CdkTableModule,
    DragDropModule
  ],
  entryComponents: [
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-CL' },
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ]

})
export class MaterialModule { }

import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA, MAT_DIALOG_DATA } from '@angular/material';
import { GlobalFunctionsService } from '../../sharedServices/global-functions.service';
import { empresa } from '../../models/mantenedores/empresa.interface';


@Component({
  selector: 'app-bottom-sheet-descripcion',
  templateUrl: './bottom-sheet-descripcion.component.html',
  styleUrls: ['./bottom-sheet-descripcion.component.scss']
})
export class BottomSheetDescripcionComponent implements OnInit {
  empresa: empresa;
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<BottomSheetDescripcionComponent>,
  ) { }
  ngOnInit() {
    this.empresa = this.data.empresas;
  }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

}

import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { filter, catchError, tap, map, switchMap } from 'rxjs/operators';
import { Observable, from, of } from 'rxjs';
import { Ubicacion } from '../models/ubicacion.interface';
import { Direccion } from '../models/direccion.interface';

declare var google: any;

@Injectable()
export class GeocodeService {
    private geocoder: any;

    constructor(private mapLoader: MapsAPILoader) { }

    private initGeocoder() {
        this.geocoder = new google.maps.Geocoder();
    }

    private waitForMapsToLoad(): Observable<boolean> {
        if (!this.geocoder) {
            return from(this.mapLoader.load())
                .pipe(
                    tap(() => this.initGeocoder()),
                    map(() => true)
                );
        }
        return of(true);
    }


    reverseGeocodeAddress(latitude, longitude): Observable<Direccion> {
        return this.waitForMapsToLoad().pipe(
            // filter(loaded => loaded),
            switchMap(() => {
                return new Observable(observer => {
                    this.geocoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
                        if (status == google.maps.GeocoderStatus.OK) {
                            observer.next({
                                address: results[0].formatted_address
                            });
                        } else {
                            observer.next({ address: "error" });
                        }
                        observer.complete();
                    });
                })
            })
        )
    }

    geocodeAddress(location: string): Observable<Ubicacion> {
        return this.waitForMapsToLoad().pipe(
            // filter(loaded => loaded),
            switchMap(() => {
                return new Observable(observer => {
                    this.geocoder.geocode({ 'address': location }, (results, status) => {
                        if (status == google.maps.GeocoderStatus.OK) {
                            observer.next({
                                formatted_address: results[0].formatted_address,
                                lat: results[0].geometry.location.lat(),
                                lng: results[0].geometry.location.lng()
                            });
                        } else {
                            observer.next({formatted_address:'', lat: 0, lng: 0 });
                        }
                        observer.complete();
                    });
                })
            })
        )
    }

}
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { registerLocaleData } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import localeEsCL from '@angular/common/locales/es-CL';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { APP_ROUTES } from './app.routes';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import { RoleGuardService } from './auth/role-guard.service';
import { globalApi } from './globalApi';
import { HomeComponent } from './home/home.component';
import { MaterialModule } from './material.module';
import { SharedFormModule } from './sharedForm.module';
import { EmitService } from './sharedServices/emitService.service';
import { ExcelService } from './sharedServices/excel.service';
import { GeocodeService } from './sharedServices/geocode.service';
import { GlobalFunctionsService } from './sharedServices/global-functions.service';
import { GpsService } from './sharedServices/gpsService.service';
import { SharedService } from './sharedServices/sharedService.service';
import { NavBarComponent } from './ui/nav-bar/nav-bar.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { InterceptorService } from './administracion/services/interceptor.service';



export function tokenGetter() {
  return localStorage.getItem('access_token');
}

registerLocaleData(localeEsCL, 'es-CL');
var uri = globalApi.uri;



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavBarComponent,
    // BottomSheetDescripcionComponent
    // characterReplacer

  ],
  imports: [
CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatGoogleMapsAutocompleteModule,
    RouterModule.forRoot(APP_ROUTES),
    MaterialModule,
    SharedFormModule,
    FormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [uri],
        blacklistedRoutes: [uri + '/auth/login']
      }
    })

  ],
  providers: [
    HttpClient,
    AuthService,
    AuthGuard,
    RoleGuardService,
    GeocodeService,
    GpsService,
    SharedService,
    EmitService,
    ExcelService,
    GlobalFunctionsService,
    { provide: LOCALE_ID, useValue: 'es-CL' },
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true}

  ],
  entryComponents: [
    // BottomSheetDescripcionComponent
  ],
  bootstrap: [AppComponent]

})
export class AppModule { }

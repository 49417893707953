

<div class="container" *ngIf="!isAuthenticated()">
    <!-- *ngIf="!reloading"  -->
    <mat-card class="center-login">
        <mat-card-header color="black"class="ingresar-titulo">
            <mat-card-title>PGE</mat-card-title>
            <mat-card-subtitle>Plataforma de Gestión Energética</mat-card-subtitle>
        </mat-card-header>

        <mat-card-content class="center-content">
            <form (ngSubmit)="submit(loginForm.value)" [formGroup]="loginForm">
                <div class="row">
                    <mat-form-field appearance="outline">
                        <mat-label>Correo electrónico</mat-label>
                        <input matInput formControlName="email" required>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field appearance="outline">
                        <mat-label>Contraseña</mat-label>
                        <input matInput type="password" formControlName="password" required>
                    </mat-form-field>
                </div>

                <div class="row center-content">
                    <button mat-raised-button class="ingresar-button" type="submit" color="primary">
                        Entrar <mat-icon class="icono-button">navigate_next</mat-icon>
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>

<!-- dialog loading -->
<ng-template #loadingDialog>
    <div style="overflow: hidden;">

        <mat-dialog-content class="mat-typography loading-modal">
            <div class="margin-y">
                <!-- BARRA LOADING -->
                <mat-progress-bar width class="mat-progressbar-home padding-x" mode="indeterminate"></mat-progress-bar>

                <div class="div-img-bus">
                    <img width="350px" src="../../assets/img/bus.gif" alt="bus">
                </div>
            </div>


        </mat-dialog-content>
    </div>

</ng-template>

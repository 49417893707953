import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MatSnackBar } from '@angular/material';
import { DialogCargadorService } from '../equipos-dinamicos/services/dialog-cargador-service.service';


@Injectable()
export class RoleGuardService implements CanActivate {
    empresa: any;
    constructor(
        public auth: AuthService, 
        public router: Router, 
        public jwtHelper: JwtHelperService, 
        private snackBar: MatSnackBar,
        private dService: DialogCargadorService,
        ) { }

    /**
     * Funcion que valida si el usuario cuenta o no con el nivel necesario designado en la ruta
     * que intenta acceder en base al nivel que indica su token de acceso
     * @param route 
     */
    canActivate(route: ActivatedRouteSnapshot): boolean {
        const expectedLevel: any = route.data.expectedLevel;
        const expectedRoles: string[] = route.data.expectedRoles;
        const token = localStorage.getItem('access_token');
        const tokenPayload = this.jwtHelper.decodeToken(token);
        if (!this.auth.loggedIn() || expectedLevel > tokenPayload.level) {
            this.router.navigate(['home']).then(() => {
                this.snackBar.open('NO TIENE ACCESO', 'OK', {
                    duration: 2500,
                    verticalPosition: 'top',
                    horizontalPosition: 'right'
                });
            });
            return false;
        }
        if (expectedRoles.length > 0 || expectedRoles != null) {
            if (tokenPayload.level >= expectedLevel || expectedRoles.includes(tokenPayload.role)) {
                return true;
            } else {
                this.router.navigate(['home']).then(() => {
                    this.snackBar.open('NO TIENE ACCESO', 'OK', {
                        duration: 2500,
                        verticalPosition: 'top',
                        horizontalPosition: 'right'
                    });
                });
                return false;
            }
        }
        return true;
    }

    /**
     * Funcion para determinar si en base a @param levelRequired el usuario cuenta o no con
     * acceso a cierto elemento
     * @param levelRequired 
     */
    hasAccess(levelRequired: number): boolean {
        if (this.auth.loggedIn()) {
            const token = localStorage.getItem('access_token');
            const tokenPayload = this.jwtHelper.decodeToken(token);
            if (levelRequired > tokenPayload.level) {
                return false;
            }
            return true;
        }
        return false;
    }

    specialMaipuAccess(minLevel: number): boolean {
        if (this.auth.loggedIn()) {
            const token = localStorage.getItem('access_token');
            const tokenPayload = this.jwtHelper.decodeToken(token);
            if (tokenPayload.level > minLevel || tokenPayload.role == "maipu") {
                return true;
            }
            return false;
        }
        return false;
    }

    specialPenalolenAccess(minLevel: number): boolean {
        if (this.auth.loggedIn()) {
            const token = localStorage.getItem('access_token');
            const tokenPayload = this.jwtHelper.decodeToken(token);
            if (tokenPayload.level > minLevel || tokenPayload.role == "penalolen") {
                return true;
            }
            return false;
        }
        return false;
    }

    isDhemax(): boolean {
        if (this.auth.loggedIn()) {
            const token = localStorage.getItem('access_token');
            const tokenPayload = this.jwtHelper.decodeToken(token);
            if (tokenPayload.role == 1) {
                return true;
            }
            return false;
        }
        return false;
    }

    sucursalAccess() {
        if (this.auth.loggedIn()) {
            const token = localStorage.getItem('access_token');
            const tokenPayload = this.jwtHelper.decodeToken(token);


        }
    }

    empresaAccess() {
        const token = localStorage.getItem('access_token');
        const tokenPayload = this.jwtHelper.decodeToken(token);

        this.empresa = tokenPayload ? tokenPayload.empresas_id : '';
        return this.empresa
    }

    ultima_empresa() {
        return this.empresa
    }

    selectEmpresa(empresa_id: number) {
        this.empresa = empresa_id
    }

    personaid() {
        const token = localStorage.getItem('access_token');
        if (token) {
            const tokenPayload = this.jwtHelper.decodeToken(token);
            //  tokenPayload.empresas_id
            return tokenPayload.persona_id
        }

    }

    usuarioId() {
        const token = localStorage.getItem('access_token');
        if (token) {
            const tokenPayload = this.jwtHelper.decodeToken(token);
            //  tokenPayload.empresas_id
            return tokenPayload.user_id
        }
    }

    roleId() {
        const token = localStorage.getItem('access_token');

        if (token) {
            const tokenPayload = this.jwtHelper.decodeToken(token);
            return tokenPayload.role;
        } else {
            return '';
        }

    }

    empresaId() {
        const token = localStorage.getItem('access_token');

        if (token) {
            const tokenPayload = this.jwtHelper.decodeToken(token);
            return tokenPayload.empresas_id;
        }
        else {
            return '';
        }
    }
    


}